import React from "react";
import { Icon, Stack } from "@storyofams/react-ui";

import { Star, StarHalf } from "../../../components/common/Icons";

export const Stars = ({ stars, isOverview = false, ...props }) => {
  const roundedRating = Math.ceil(stars);
  const r = Math.floor(stars);
  return (
    <Stack space={0.25} {...props}>
      {[1, 2, 3, 4, 5].map((i) => (
        <Icon
          fontSize={isOverview ? "24px" : "18px"}
          key={`star-${i}`}
          icon={i - 1 >= r && i - 1 < roundedRating ? StarHalf : Star}
          color="oldPink"
          opacity={i <= roundedRating ? 1 : 0.2}
        />
      ))}
    </Stack>
  );
};
