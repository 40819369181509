import { useMemo } from "react";
import { Box, css, Flex, Icon, Stack, Text } from "@storyofams/react-ui";

import { createClient } from "@supabase/supabase-js";
import Image from "next/image";
import Link from "next/link";
import { useQuery } from "react-query";
import { Star } from "~components/common/Icons";
import { RecipesCategories } from "./RecipesCategories";

export const RecipesMenu = ({ megaData, close }) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeItems = async (): Promise<any> => {
    const ids = [
      ...megaData.popular_recipes.map((item) => item.title),
      megaData.recipe_card,
    ];
    try {
      //@ts-ignore
      let query = supabase
        .from("recipes_with_rating")
        .select(
          "*, contents_uom(text), difficulty(text), makes_uom(text), summary(text), title(text), recipes_ingredients(title)"
        )
        .in("slug", ids);
      const { data } = await query;
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems } = useQuery(["getRecipeItems"], getRecipeItems, {
    enabled: true,
  });
  const recipes = useMemo(() => {
    let card;
    let items;
    if (recipeItems?.length) {
      card = recipeItems.find(({ slug }) => slug === megaData.recipe_card);
      items = recipeItems.filter(({ slug }) => slug !== megaData.recipe_card);
    }
    return { card, items };
  }, [recipeItems]);
  return (
    <Box
      top={50}
      position={"absolute"}
      width={"100%"}
      bg="white"
      borderRadius={"6px"}
      boxShadow={"0px 10px 60px 0px #0000001F"}
    >
      <Stack space={"30px"} display={"flex"} width={"100%"} p={"20px"}>
        <Box width="51%" maxWidth={"612px"}>
          <Box width={"100%"}>
            <RecipesCategories close={close} />
          </Box>
        </Box>

        <Box width="24.5%">
          {recipes.card && (
            <>
              <Text
                mb={"15px"}
                lineHeight={"21px"}
                fontFamily="DINPro"
                fontWeight={500}
                css={{ textTransform: "uppercase" }}
                fontSize="15px"
              >
                Uitgelicht
              </Text>
              <Link prefetch={false} href={`/recipes/${recipes.card.slug}`}>
                <Box
                  p="16px"
                  border={"1px solid #E2DFDF"}
                  borderRadius={"md"}
                  cursor={"pointer"}
                  onClick={() => {
                    close();
                    // posthog.capture("Clicked Menu", {
                    //   tab: "Recepten",
                    //   item: "Recipe big card",
                    // });
                  }}
                >
                  <Box
                    cursor="pointer"
                    height={206}
                    position={"relative"}
                    css={css({
                      img: {
                        borderRadius: "md",
                      },
                    })}
                  >
                    <Image
                      src={recipes?.card?.image + "/m/350x350"}
                      layout="fill"
                      objectFit={"cover"}
                    />
                  </Box>
                  <Flex
                    mt="18px"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily="DINPro"
                      color="grey600"
                      fontWeight={500}
                      fontSize={"12px"}
                      letterSpacing={"0.1em"}
                      lineHeight={"16.2px"}
                      pt={"2px"}
                      css={{ textTransform: "uppercase" }}
                    >
                      {"Recept"}
                    </Text>
                    {!!recipes?.card?.rating && (
                      <Box
                        display={"flex"}
                        fontWeight="bold"
                        alignItems="center"
                      >
                        <Text
                          lineHeight={"19.6px"}
                          fontSize={"14px"}
                          fontWeight={700}
                          pl={1}
                          pt={"1px"}
                        >
                          {recipes?.card?.rating}
                        </Text>
                        <Icon
                          ml={1}
                          fontSize={"14px"}
                          color="oldPink"
                          icon={Star}
                        />
                      </Box>
                    )}
                  </Flex>
                  <Text
                    mt="8px"
                    fontFamily="DINPro"
                    fontWeight={700}
                    lineHeight={"25.2px"}
                    fontSize={"18px"}
                  >
                    {recipes?.card?.title?.text}
                  </Text>
                </Box>
              </Link>
            </>
          )}
        </Box>
        <Box width="24.5%">
          <Text
            mb={"15px"}
            lineHeight={"21px"}
            fontFamily="DINPro"
            fontWeight={500}
            css={{ textTransform: "uppercase" }}
            fontSize="15px"
          >
            Populaire recepten
          </Text>
          {recipes.items &&
            recipes.items.map((recipe, i) => {
              return (
                <Link
                  prefetch={false}
                  href={`/recipes/${recipe.slug}`}
                  key={recipe.slug}
                >
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      close();
                      // posthog.capture("Clicked Menu", {
                      //   tab: "Recepten",
                      //   item: recipe?.title.text,
                      // });
                    }}
                  >
                    <Box display="flex">
                      <Box
                        css={css({
                          img: {
                            borderRadius: "md",
                          },
                        })}
                      >
                        {recipe?.image && (
                          <Image
                            src={recipe.image}
                            width={60}
                            height={60}
                            objectFit="cover"
                          />
                        )}
                      </Box>

                      <Box ml={"12px"}>
                        <Text
                          mt={1}
                          fontFamily="DINPro"
                          color="grey600"
                          fontWeight={500}
                          fontSize={"12px"}
                          letterSpacing={"0.1em"}
                          lineHeight={"16.2px"}
                          mb={"2px"}
                          css={{ textTransform: "uppercase" }}
                        >
                          {"Recept"}
                        </Text>
                        <Text
                          fontWeight={600}
                          fontSize={"18px"}
                          maxWidth={"230px"}
                          lineHeight={"25.2px"}
                        >
                          {recipe?.title.text}
                        </Text>
                      </Box>
                    </Box>
                    {i !== recipes?.items?.length - 1 && (
                      <Box borderTop="1px solid #E2DFDF" my={"12px"} />
                    )}
                  </Box>
                </Link>
              );
            })}
        </Box>
      </Stack>
    </Box>
  );
};
