import { FC, useMemo } from "react";

import {
  Stack,
  Icon,
  Flex,
  Box,
  SystemProps,
  Text,
} from "@storyofams/react-ui";
import { sumBy } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { css } from "styled-components";
import { CartOverlay, Drawer, Heading, Button } from "../../../../components";
import { useShopify, useStamped } from "../../../../context";

import { shopifyToIntlPrice } from "../../../../lib";
import { CartItem, FooterItem } from "../../../../lib/storyblok/sdk";
import {
  Cross,
  Logo,
  Search,
  LogoCompact,
  LogoBig,
  NhUser,
  NhHeart,
  NhCart,
} from "../../Icons";

import { Badge } from "./Badge";
import { Hamburger } from "./Hamburger";

interface NavigationPrimaryProps extends SystemProps {
  mobileMenuOpen: boolean;
  scrolledStyles?: any;
  setMobileMenuOpen(boolean): void;
  paymentOptions: FooterItem["content"]["payment_options"];
  cartNote: CartItem["content"]["payment_note"];
  isScrolled: Boolean;
  open?: () => void;
}

export const NavigationPrimary: FC<NavigationPrimaryProps> = ({
  children,
  mobileMenuOpen,
  setMobileMenuOpen,
  paymentOptions,
  cartNote,
  open,
  scrolledStyles,
  isScrolled,
  ...props
}) => {
  const posthog = usePostHog();
  const { customer, showCartOverlay, cart, toggleCartOverlay } = useShopify();
  const { wishlistCount } = useStamped();
  const quantity = useMemo(
    () =>
      sumBy(cart?.lines?.edges, function (item) {
        return item?.node?.quantity;
      }),
    [cart]
  );
  const router = useRouter();
  return (
    <>
      <Flex
        mx={"auto" as any}
        height={["max-content", "52px", "80px"]}
        maxWidth="1332px"
        alignItems="center"
        pr={1}
        pl={2}
        {...props}
      >
        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          transition="background-color .24s ease-out"
          css={css(scrolledStyles)}
        />
        <Hamburger
          onClick={() => {
            setMobileMenuOpen(true);
            // posthog.capture("Clicked Menu");
          }}
        />
        <Button
          mt={-0.75}
          variant="link"
          href="/"
          ariaLabel="Natural Heroes logo"
        >
          <Icon
            display={{ sm: "none !important" }}
            icon={LogoCompact}
            fontSize={"29px"}
          />
          <Icon
            display={{ _: "none !important", sm: "inline-flex !important" }}
            icon={Logo}
            height="32px"
            width="166px"
            css={css({ svg: { height: "100%", width: "100%" } })}
          />
        </Button>
        {router.pathname !== "/search" && isScrolled && (
          <Box
            display={["block", "block", "none"]}
            width={"100%"}
            css={css({
              input: { minHeight: "39px", py: "9px" },
              svg: { width: "16px" },
            })}
            mx="20px"
            overflow={"hidden"}
          >
            <Button
              width={"100%"}
              onClick={open}
              variant="unstyled"
              borderStyle="solid"
              borderWidth="1px"
              borderColor="grey400"
              bg="white"
              py="9px"
              fontSize={2}
              borderRadius="sm"
              justifyContent="space-between"
              alignItems="center"
              pl={2}
              pr={5}
              color="grey400"
              cursor="text"
              overflow={"hidden"}
              css={{ textOverflow: "ellipsis" }}
              whiteSpace={"nowrap"}
            >
              Zoek naar product, recept, of onderwerp
              {/* <FormattedMessage {...messages.searchPlaceholder} /> */}
              <Icon
                display="flex"
                alignItems="center"
                position="absolute"
                right={1.5}
                top={0}
                bottom={0}
                color="grey800"
                fontSize={3}
                icon={Search}
              />
            </Button>
          </Box>
        )}
        <Box
          position={["fixed", "fixed", "static"]}
          display={["flex !important", "flex !important", "none !important"]}
          top={[0, 0, "unset"]}
          left={[
            mobileMenuOpen ? 0 : "-100%",
            mobileMenuOpen ? 0 : "-100%",
            "unset",
          ]}
          bottom={[0, 0, "unset"]}
          width="100%"
          backgroundColor={"rgba(29,29,27,.4)" as any}
          zIndex={"overlay" as any}
          transition="opacity .24s ease-out"
          opacity={[mobileMenuOpen ? 1 : 0, mobileMenuOpen ? 1 : 0, 0]}
          onClick={() => setMobileMenuOpen(false)}
        />

        <Flex
          flexDirection="column"
          position={["fixed", "fixed", "static"]}
          top={[0, 0, "unset"]}
          left={[
            mobileMenuOpen ? 0 : "-100%",
            mobileMenuOpen ? 0 : "-100%",
            "unset",
          ]}
          bottom={[0, 0, "unset"]}
          flex={1}
          backgroundColor={["white", "white", "unset"]}
          zIndex={"overlay" as any}
          transition="left .24s ease-out"
          maxWidth={["320px", "320px", "none"]}
          height="100%"
          width="100%"
        >
          <Box
            width="100%"
            height="52px"
            display={["flex", "flex", "none"]}
            alignItems="center"
            justifyContent="space-between"
            px={2}
            as="button"
            onClick={() => setMobileMenuOpen(false)}
          >
            <Icon
              display={{ md: "none !important" }}
              icon={LogoBig}
              fontSize={"180px"}
            />
            <Icon fontSize={3} icon={Cross} />
          </Box>

          {children}

          <Box
            px={[2, 2, 5]}
            py={[3, 3, 0]}
            display={["flex", "flex", "none"]}
            bg="#FCF9F7"
          >
            <Stack space={"14px"} flexDirection="column">
              <Link
                color="grey600"
                href={customer ? "/account" : "/api/auth/login"}
              >
                <Flex
                // onClick={() => posthog.capture("Clicked Account")}
                >
                  <Icon fontSize={"20px"} mr={2} icon={NhUser}></Icon>
                  <Text
                    pt="3px"
                    fontFamily={"DINPro"}
                    fontWeight={500}
                    fontSize={"12px"}
                    lineHeight={"16.2px"}
                    letterSpacing={"0.1em"}
                    css={{ textTransform: "uppercase" }}
                  >
                    Mijn account
                  </Text>
                </Flex>
              </Link>

              <Link color="grey600" href="/wishlist">
                <Flex
                // onClick={() => posthog.capture("Clicked Wishlist")}
                >
                  <Box position={"relative"} mr={2}>
                    {!!wishlistCount && (
                      <Badge position="absolute" top={"-1px"} right={"-3px"}>
                        {wishlistCount}
                      </Badge>
                    )}
                    <Icon fontSize={"20px"} icon={NhHeart}></Icon>
                  </Box>
                  <Text
                    pt="3px"
                    fontFamily={"DINPro"}
                    fontWeight={500}
                    lineHeight={"16.2px"}
                    fontSize={"12px"}
                    letterSpacing={"0.1em"}
                    css={{ textTransform: "uppercase" }}
                  >
                    favorieten
                  </Text>
                </Flex>
              </Link>
              <Link prefetch={false} href="/cart">
                <Flex
                // onClick={() => posthog.capture("Clicked Cart")}
                >
                  <Box position={"relative"} mr={2}>
                    {!!quantity && (
                      <Badge position="absolute" top={"-1px"} right={"-3px"}>
                        {quantity}
                      </Badge>
                    )}
                    <Icon fontSize={"20px"} icon={NhCart}></Icon>
                  </Box>
                  <Text
                    pt="3px"
                    lineHeight={"16.2px"}
                    fontWeight={500}
                    fontSize={"12px"}
                    letterSpacing={"0.1em"}
                    fontFamily={"DINPro"}
                    css={{ textTransform: "uppercase" }}
                  >
                    Winkelmand
                  </Text>
                </Flex>
              </Link>
              {/* <NavigationLink
                color="grey600"
                onClick={() => {
                  setMobileMenuOpen(false);
                  // TODO: 3300 add new functionality after adding new tool instead of helpscout
                }}
              >
                help
              </NavigationLink> */}
            </Stack>
          </Box>
        </Flex>

        <Flex
          ml={["auto" as any, "auto" as any, 0]}
          alignItems="center"
          position="relative"
        >
          {/* <SelectLanguageMenu>
            <Button
              href="menu-button"
              display="inline-flex"
              minWidth={["32px", "32px", "40px"]}
              height="40px"
              alignItems="center"
              justifyContent="center"
              mr={1}
            >
              <Icon icon={Translate} color="grey800" fontSize={4} />
            </Button>
          </SelectLanguageMenu> */}
          <Button
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr={[2, "28px"]}
            // onClick={() => posthog.capture("Clicked Account")}
            href={customer ? "/account" : "/api/auth/login"}
          >
            <Icon icon={NhUser} color="grey800" fontSize={["20px", "26px"]} />
          </Button>

          <Button
            href="/wishlist"
            display="inline-flex"
            height="40px"
            alignItems="center"
            mr={[2, "28px"]}
            justifyContent="center"
            // onClick={() => posthog.capture("Clicked Wishlist")}
          >
            <Icon icon={NhHeart} color="grey800" fontSize={["20px", "26px"]} />
            {!!wishlistCount && (
              <Badge
                position="absolute"
                top={["9px", "5px"]}
                right={["-3px", "-5px"]}
              >
                {wishlistCount}
              </Badge>
            )}
          </Button>
          <Button
            href="/cart"
            display="inline-flex"
            height="40px"
            alignItems="center"
            mr={"10px"}
            // onClick={() => posthog.capture("Clicked Cart")}
            justifyContent="center"
          >
            <Icon icon={NhCart} color="grey800" fontSize={["20px", "26px"]} />
            {!!quantity && (
              <Badge
                position="absolute"
                top={["9px", "5px"]}
                right={["-3px", "-4px"]}
              >
                {quantity}
              </Badge>
            )}
          </Button>
        </Flex>
      </Flex>

      <Drawer
        isOpen={showCartOverlay}
        close={toggleCartOverlay}
        px={[0, 0]}
        title={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flex={1}
            py={3}
          >
            {cart?.cost?.totalAmount && (
              <Heading
                variant="h5"
                as="h4"
                fontSize={1.75}
                css={{ textTransform: "uppercase" }}
              >
                Winkelmand ({quantity}) -{" "}
                {shopifyToIntlPrice(cart?.cost?.totalAmount)}
              </Heading>
            )}
          </Flex>
        }
      >
        <CartOverlay paymentOptions={paymentOptions} cartNote={cartNote} />
      </Drawer>
    </>
  );
};
