import { Box, Grid, css, Text, Flex } from "@storyofams/react-ui";

import { Image } from "@storyofams/storyblok-toolkit";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { getLinkProps } from "../../../../lib";

export const CardsMenu = ({ megaData, close }) => {
  const posthog = usePostHog();
  return (
    <Box
      top={50}
      position={"absolute"}
      width={"100%"}
      bg="white"
      boxShadow={"0px 10px 60px 0px #0000001F"}
      borderRadius={"6px"}
    >
      <Grid rowSize={4}>
        {megaData.items.map((item, i) => (
          <Link
            prefetch={false}
            href={getLinkProps(item.link)}
            key={item.title}
          >
            <Flex
              onClick={() => {
                close();
                // posthog.capture("Clicked Menu", {
                //   tab: megaData?.label,
                //   item: item?.title,
                // });
              }}
              borderRight={(i + 1) / 4 !== 1 && "1px solid #F7F0E8"}
              p="23px"
              cursor={"pointer"}
              alignItems={"center"}
              borderBottom={
                megaData.items.length > 3 && i < 4 && "1px solid #F7F0E8"
              }
              justifyContent={"space-between"}
            >
              <Box>
                <Text
                  css={{ textTransform: "uppercase" }}
                  fontFamily={"DINPro"}
                  fontWeight={500}
                  fontSize={"15px"}
                  mb="8px"
                >
                  {item?.title}
                </Text>
                <Text color="#767473" fontSize={"15px"}>
                  {item?.text}
                </Text>
              </Box>
              {item?.image?.filename && (
                <Box
                  ml="15px"
                  position={"relative"}
                  css={css({
                    img: {
                      borderRadius: "md",
                    },
                  })}
                >
                  <Image
                    fluid={150}
                    width={78}
                    height={60}
                    src={item.image.filename}
                  />
                </Box>
              )}
            </Flex>
          </Link>
        ))}
      </Grid>
    </Box>
  );
};
