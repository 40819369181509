import React, { ReactNode } from "react";
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from "@reach/dialog";
import { SystemProps, Icon } from "@storyofams/react-ui";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Heading, Text } from "../../../components";
import { Button } from "../../../components/common/Button";
import { Cross } from "../../../components/common/Icons";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<SystemProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0.75;

  padding: ${(p) => p.theme.space[4]}px;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: ${(p) => p.theme.space[4]}px ${(p) => p.theme.space[2]}px 92px;
  }
  @media (max-width: 420px) {
    padding: 0;
  }
`;

const DialogContent = styled(MotionDialog)<{ children?: ReactNode }>`
  position: relative;
  flex: 1;

  padding: 0;
  margin: 0;
  max-height: 100%;
  background: transparent;
  max-height: calc(100vh - 160px);
  width: 100%;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    max-width: 486px;
  }
  @media (max-width: 420px) {
    max-width: none;
    max-height: none;
    width: 100%;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: color 0.2s;
  z-index: 2;
`;

const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  height: 100%;
  background: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  @media (max-width: 420px) {
    max-width: none;
    max-height: none;
    width: 90%;
    height: 100%;
  }
`;

interface ModalProps extends SystemProps {
  isOpen: boolean;
  close(): void;
  children: ReactNode;
  ariaLabel?: string;
}

export const Modal = ({
  title,
  description,
  children,
  ariaLabel,
  isOpen,
  close,
  borderRadius,
  isDisabled = false,
  cross = true,
  isNoClickOutside = false,
  isBlured = false,
}: any) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay
          onDismiss={!isNoClickOutside ? close : () => null}
          css={(isDisabled || isBlured) && { "backdrop-filter": "blur(6px)" }}
          initial={{ opacity: isDisabled ? 1 : 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <DialogContent
            aria-label={ariaLabel || "modal"}
            initial={{ y: -10 }}
            animate={{ y: 0 }}
            exit={{ y: -10 }}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              className={borderRadius && "active"}
            >
              {cross && !isDisabled && (
                <CloseButton
                  variant="unstyled"
                  onClick={close}
                  aria-label="Close modal"
                >
                  <Icon icon={Cross} fontSize={2.5} color="grey900" />
                </CloseButton>
              )}

              {title && (
                <Heading
                  variant="h2"
                  as="h2"
                  fontSize={5}
                  mb={3}
                  textAlign="center"
                >
                  {title}
                </Heading>
              )}

              {description && (
                <Text mb={4} fontSize={2.25}>
                  {description}
                </Text>
              )}

              {children}
            </ContentWrapper>
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};
